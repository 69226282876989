import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import sections
import ReactGA from "react-ga";
import ScrollReveal from "../../utils/ScrollReveal";
import GetInTouch from "../../components/sections/contact-us/GetInTouch";
const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const ContactUs = () => {
  const childRef = useRef();
  let location = useLocation();
  const [dataContent, setDataContent] = useState({});
  const page = location.pathname;

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();

    const fetchData = async () => {
        await axios.get('/assets/content/contact.json').then((response) => {
          setDataContent(response.data);
        }).cache((error) => {

        }); 
      };
   
     fetchData();
   
    trackPage(page);
  }, [location, page]);
  
  

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <React.Fragment>
          {/* {page} */}
          <div className="bg-top" /> 
          <GetInTouch id="GetInTouch" dataContent={dataContent} />
          <div className="bg-bottom" />
        </React.Fragment>
      )}
    />
  );
};

export default ContactUs;
