import React, { useEffect, useRef, useState } from "react";
import { SectionTilesProps } from "../../../utils/SectionProps";
import { Col, FormGroup, Label, Input } from "reactstrap";
import { Link as LinkPage } from "react-router-dom";
import classNames from "classnames";
import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

import get_in_touch_img from "./../../../assets/images/contact-us/get_in_touch.svg";
import icon_phone from "./../../../assets/images/contact-us/icon_phone.svg";
import icon_email from "./../../../assets/images/contact-us/icon_email.svg";
import icon_facebook from "./../../../assets/images/contact-us/icon_facebook.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const GetInTouch = ({
  topOuterDivider,
  bottomOuterDivider,
  hasBgColor,
  invertColor,
  className,
  topDivider,
  bottomDivider,
  dataContent,
  ...props
}) => {
  const form = useRef();
  const recaptcha = useRef();
  const sendBtn = document.getElementById("sendButton");
  const [verifed, setVerified] = useState(false);
  const [verifedMsg, setVerifedMsg] = useState("");
  const [reCaptchaValue, setReCaptchaValue] = useState("");

  useEffect(() => { }, [reCaptchaValue, verifed]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (verifed) {
      sendBtn.value = "Sending...";

      const data = e.target;
      const name = data[0].value;
      const company = data[1].value;
      const email = data[2].value;
      const phone = data[3].value;
      const msg = data[4].value;

      if (name && company && email && phone && msg) {
        await emailjs
          .sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_PUBLIC_KEY
          )
          .then(
            (result) => {
              sendBtn.value = "Send Message";
              alert("Sent successfully!");
              window.location.reload(false);
              // console.log("Send Message :", result.text);
            },
            (error) => {
              sendBtn.value = "Send Message";
              alert(JSON.stringify(error));
              // console.log("Send Message :", error.text);
            }
          );
      } else {
        alert(
          "Please enter all your data : name, company, email, phone and message!"
        );
        sendBtn.value = "Send Message";
      }
    } else {
      setVerifedMsg("Please verify that you are not a robot.");
    }
  };

  const sendEmail = (captchaValue) => {
    // console.log("captchaValue :>> ", captchaValue);
    if (captchaValue) {
      setReCaptchaValue(captchaValue);
      setVerified(true);
      setVerifedMsg("");
    }
  };

  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const contentClasses = classNames("row-content");

  const tilesClasses = classNames(
    "tiles-wrap center-content custom-pt-contactus"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* TOP */}
          <div className={contentClasses}>
            <div
              className="tiles-item-contactus-top-left reveal-from-left"
              data-reveal-delay="200"
            >
              <h2 className="justify-title-contactus text-has-shadow mt-0 mb-16">
                <span>{`Get In `}</span>
                <span className="text-color-primary text-nowrap">Touch</span>
              </h2>

              {/* {JSON.stringify(dataContent)} */}

              <p className="text-xs">
                {dataContent && dataContent?.detail}
                {/* Something you wanna talk to us about? Well give us a ring, send
                us an email, or fill out that nifty form below. */}
              </p>
            </div>
            <div
              className="tiles-item-contactus-top-right reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="h-content-for-w-img-500px">
                  <img
                    src={get_in_touch_img}
                    alt="Get in touch"
                    className="w-img-500px img-has-shadow"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* BOTTOM */}
          <div
            className="bg-img-contact-us reveal-from-bottom"
            data-reveal-delay="400"
          >
            <div className={(contentClasses, tilesClasses)}>
              <div
                className="tiles-item-contactus-bottom-left content-has-shadow reveal-from-left"
                data-reveal-delay="200"
              >
                <div className="item-contactus-bottom">
                  <form
                    className="w-100-contactus"
                    id="getInTouchForm"
                    ref={form}
                    onSubmit={handleSubmit}
                  >
                    <FormGroup row className="drop-message-title my-0">
                      <Label className="pt-0">
                        <div className="justify-detail-contactus m-0">
                          <h5 className="drop-message-title m-0">
                            Drop a Message
                          </h5>
                        </div>
                      </Label>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Label
                        className="drop-message-label pt-0"
                        sm={12}
                        md={5}
                        lg={3}
                      >
                        <p className="justify-detail-contactus text-xs m-0">
                          Name :
                        </p>
                      </Label>
                      <Col sm={12} md={7} lg={9}>
                        <Input
                          type="text"
                          name="name"
                          className="text-primary text-xs"
                        // placeholder="Name"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Label
                        className="drop-message-label pt-0"
                        sm={12}
                        md={5}
                        lg={3}
                      >
                        <p className="justify-detail-contactus text-xs m-0">
                          Company :
                        </p>
                      </Label>
                      <Col sm={12} md={7} lg={9}>
                        <Input
                          type="text"
                          name="company"
                          className="text-primary text-xs"
                        // placeholder="Company"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Label
                        className="drop-message-label pt-0"
                        sm={12}
                        md={5}
                        lg={3}
                      >
                        <p className="justify-detail-contactus text-xs m-0">
                          Email :
                        </p>
                      </Label>
                      <Col sm={12} md={7} lg={9}>
                        <Input
                          id="emailMsg"
                          type="email"
                          name="email"
                          className="text-primary text-xs"
                        // placeholder="Enter email"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Label
                        className="drop-message-label pt-0"
                        sm={12}
                        md={5}
                        lg={3}
                      >
                        <p className="justify-detail-contactus text-xs m-0">
                          Phone :
                        </p>
                      </Label>
                      <Col sm={12} md={7} lg={9}>
                        <Input
                          id="phoneMsg"
                          type="text"
                          name="phone"
                          className="text-primary text-xs"
                        // placeholder="Phone"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Label
                        className="drop-message-label pt-0"
                        sm={12}
                        md={5}
                        lg={3}
                      >
                        <p className="justify-detail-contactus text-xs m-0">
                          Message :
                        </p>
                      </Label>
                      <Col sm={12} md={7} lg={9}>
                        <Input
                          type="textarea"
                          name="message"
                          className="text-primary text-xs"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="form_group_recaptcha">
                      <ReCAPTCHA
                        ref={recaptcha}
                        className="flex align-items-center justify-content-center m-0"
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={sendEmail}
                        onExpired={() => {
                          setReCaptchaValue("");
                          setVerified(false);
                        }}
                        datasize="compact"
                      />
                      <div
                        className="pb-2"
                        hidden={!verifed && verifedMsg ? false : true}
                      >
                        <Label className="verifed-message p-0 m-0">
                          {verifedMsg}
                        </Label>
                      </div>
                      <input
                        id="sendButton"
                        className="submit-btn text-xxs mt-2"
                        type="submit"
                        value="Send Message"
                      />
                    </FormGroup>
                  </form>
                </div>
              </div>
              <div
                className="tiles-item-contactus-bottom-right content-has-shadow reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="">
                  <h5 className="text-invert-contactus my-0 pb-3">
                    Contact Information
                  </h5>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center py-3">
                      <div className="h-content-for-w-img-30px">

                      </div>
                      <div>
                        <p className="justify-detail-contactus text-invert-contactus text-xs mb-0">
                          <div className="d-flex">
                            <div>
                              <img
                                src={"favicon.ico"}
                                alt="Trade Square"
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                            <b className="ml-8"> {dataContent && dataContent?.name} </b></div>
                          <br />
                          <b>Company Office</b> <br />
                          {/* 1845 9 Soi Borirak, Phahonyothin Rd., Lat Yao, Chatuchak, Bangkok, Thailand 10900 */}
                          {dataContent && dataContent?.company_office} 
                        </p>

                        <p className="justify-detail-contactus text-invert-contactus text-xs mb-0">

                          <br />
                          <b>Registered Address</b> <br />
                          {/* 141/21 Soi Phahonyothin 32, Senanikhom, Chatuchak, Bangkok 10900 */}
                          {dataContent && dataContent?.register_office} 
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-3">
                      <div className="h-content-for-w-img-30px">
                        <img
                          src={icon_phone}
                          alt="Phone"
                          className="w-img-30px"
                        />
                      </div>
                      <p className="justify-detail-contactus text-invert-contactus text-xs mb-0">
                        066-1548798
                        {/* {dataContent && dataContent?.phone}  */}
                      </p>
                    </div>
                    <div className="d-flex align-items-center py-3">
                      <div className="h-content-for-w-img-30px">
                        <img
                          src={icon_email}
                          alt="Email"
                          className="w-img-30px"
                        />
                      </div>
                      <p className="justify-detail-contactus text-invert-contactus text-xs mb-0">
                        {/* sasina@tradesquareltd.com */}
                        {dataContent && dataContent?.mail} 
                      </p>
                    </div>
                    <div className="d-flex align-items-center py-3">
                      <div className="h-content-for-w-img-30px">
                        <img
                          src={icon_facebook}
                          alt="Facebook"
                          className="w-img-30px"
                        />
                      </div>
                      <p className="justify-detail-contactus text-invert-contactus text-xs mb-0">
                        <LinkPage
                          className="link-invert-contactus"
                          to={{
                            pathname: dataContent?.facebook_link ,
                          }}
                          target={"_blank"}
                        >
                          {/* facebook.com/tradesquareltd */}
                          {dataContent && dataContent?.phone} 
                        </LinkPage>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

GetInTouch.propTypes = propTypes;
GetInTouch.defaultProps = defaultProps;

export default GetInTouch;
